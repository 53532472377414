<template>
    <div>

        <div v-if="documents.length > 0">
            <div class="doc" v-for="(doc, index) of documents" :key="doc._id">
                <a :href="doc.file.location" target="_blank">{{ DOC_TYPES[doc.type] || 'Other' }}</a>
                <button class="remove" @click="removeDoc(doc._id, index)"><i
                        class="fa-solid fa-circle-xmark"></i></button>
            </div>
        </div>

        <div v-else>
            <NoDataMessage style="padding: 0 30px;" noText="true" icon="fa-solid fa-file-circle-question"/>
        </div>

    </div>
</template>

<script>
import FilePicker from '../../../mixins/FilePicker';

export default {
    mixins: [FilePicker],
    props: ['loadId'],
    data() {
        return {
            documents: [],
            DOC_TYPES: {
                POD: 'Proof Of Delivery',
                BOL: 'Bill Of Lading',
                LOAD_PICS: 'Load Picture',
                OTHER: 'Other',
            },
            uploadOptions: [
                { name: 'Proof Of Delivery', emit: 'upload', emitValue: 'POD' },
                { name: 'Bill Of Lading', emit: 'upload', emitValue: 'BOL' },
                { name: 'Load Pictures', emit: 'upload', emitValue: 'LOAD_PICS' },
                { name: 'Other', emit: 'upload', emitValue: 'OTHER' }
            ]
        }
    },
    mounted() {
        this.getAllDocs();
        this.$emit('ready');
    },
    methods: {
        async removeDoc(docId, index) {

            let confirmdelete = await this.$ShowConfirm(`Do you really wish to delete this document? You won't be able to undo this action.`);
            if (!confirmdelete) return;

            this.documents.splice(index, 1);

            this.ajax('RemoveDocument', {
                url: `/dispatch/loads/${this.loadId}/documents/${docId}`,
                method: 'DELETE',
            }, (err, body) => { });

        },
        getAllDocs() {
            this.ajax('GetAllDocuments', {
                url: `/dispatch/loads/${this.loadId}/documents`,
                method: 'GET',
            }, (err, body) => {
                if (!err) {
                    this.documents = body;
                }
            });
        },
        uploadDoc(docType) {
            this.createFilePicker(null, async (file) => {

                var data = new FormData();
                data.append('file', file);

                await this.ajax('FileStorageUpload', {
                    url: `/dispatch/loads/${this.loadId}/documents/${docType}`,
                    method: 'POST',
                    data: data,
                }, (err, body) => {
                    if (err) {
                        this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        return;
                    }
                    this.documents.unshift(body);
                });

            });
        }
    },
}
</script>

<style lang="scss" scoped>
.doc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;

    .remove {
        display: none;
        border: 0;
        padding: 0;
        color: $error;
        background: transparent;
        cursor: pointer;
    }

    &:hover {
        .remove {
            display: block;
        }
    }
}
</style>