<template>
    <div class="view-load-wrapper">

        <AppMap height="50%" ref="AppMap" v-if="load">
            <AppMapDirections :stops="load.stops"
                @ready="(polyline) => { this.$refs['AppMap'].fitBounds(polyline.getBounds()) }" />
        </AppMap>


        <div class="load-details-grid" v-if="load">

            <div class="grid gap-20">

                <div class="load-item">
                    <div class="name">
                        <div class="text">Load details</div>
                        <div class="link" v-if="isAllowedToEdit"><a href="" @click.prevent="editLoadDetails">Edit</a>
                        </div>
                    </div>
                    <div class="value filled grid gap-10">

                        <div class="item-row">
                            <div class="item-name">Rate</div>
                            <div class="item-value rate">${{ CONVERT_PRICE(load.rate.toFixed(0)) }}</div>
                        </div>


                        <div class="item-row">
                            <div class="item-name">Rate confirmation</div>
                            <div class="item-value text-overflow"><a :href="load.rateConfirmation.location"
                                    target="_blank">{{ load.rateConfirmation.originalname }}</a></div>
                        </div>

                        <div class="item-row" v-if="load.distance">
                            <div class="item-name">Rate per mile</div>
                            <div class="item-value ratePerMile">${{ CONVERT_PRICE((load.rpm).toFixed(2)) }}</div>
                        </div>

                        <div class="item-row" v-if="load.deadHead">
                            <div class="item-name">Deadhead Miles</div>
                            <div class="item-value"><strong>{{ CONVERT_PRICE(load.deadHead) }}</strong> miles</div>
                        </div>


                        <div class="item-row" v-if="load.distance">
                            <div class="item-name">Trip Miles</div>
                            <div class="item-value"><strong>{{ CONVERT_PRICE(load.distance) }}</strong> miles
                                (<strong>{{ CONVERT_PRICE(load.tripMiles) }}</strong>)</div>
                        </div>

                        <div class="item-row">
                            <div class="item-name">Weight</div>
                            <div class="item-value"><strong>{{ CONVERT_PRICE(load.weight) }}</strong> lbs</div>
                        </div>

                        <div class="item-row">
                            <div class="item-name">Length</div>
                            <div class="item-value"><strong>{{ load.length }}</strong> ft</div>
                        </div>

                    </div>
                </div>


                <div class="load-item" v-if="load.stops">
                    <div class="name">
                        <div class="text">Load trip</div>
                        <div class="link" v-if="isAllowedToEdit"><a href="" @click.prevent="editLoadStops">Edit</a>
                        </div>
                    </div>
                    <div class="value filled stops-list">
                        <div class="stop-item" :class="[stop.type]" :key="key" v-for="(stop, key) of load.stops">
                            <div class="stop-type">{{ stop.type }} {{ stop.typeKey }}</div>
                            <div class="stop-address">
                                <div class="stop-name" v-if="stop.place.name"><i
                                        class="fa-regular fa-building"></i>&nbsp;{{ stop.place.name }}</div>
                                <div class="stop-street" v-if="stop.place.street">{{ stop.place.street }}</div>
                                <div class="stop-city-state" v-if="stop.place.cityState">{{ stop.place.cityState }}
                                </div>
                            </div>
                            <div class="stop-date"><i class="fa-regular fa-clock"></i>&nbsp;{{ stopDate(stop.date) }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="grid gap-20">

                <div class="load-item">
                    <div class="name">
                        <div class="text">Carrier details</div>
                        <div class="link" v-if="isAllowedToEdit"><a href="" @click.prevent="editCarrierDetails">Edit</a>
                        </div>
                    </div>
                    <div class="value filled grid gap-10">

                        <div class="item-row">
                            <div class="item-name">Carrier name</div>
                            <div class="item-value text-overflow">{{ load.carrier.companyName }}</div>
                        </div>

                        <div class="item-row">
                            <div class="item-name">Driver</div>
                            <div class="item-value text-overflow">{{ load.driver.name }} {{ load.driver.lastname }}
                            </div>
                        </div>

                        <div class="item-row" v-if="load.emptyCity && load.emptyCity.cityState">
                            <div class="item-name">Empty City</div>
                            <div class="item-value text-overflow">{{ load.emptyCity.cityState }}</div>
                        </div>

                    </div>
                </div>

                <div class="load-item">
                    <div class="name">
                        <div class="text">Broker details</div>
                        <div class="link" v-if="isAllowedToEdit"><a href="" @click.prevent="editBrokerDetails">Edit</a>
                        </div>
                    </div>
                    <div class="value filled grid gap-10">

                        <div class="item-row" v-if="load.brokerLoadNumber">
                            <div class="item-name">Load #</div>
                            <div class="item-value text-overflow">{{ load.brokerLoadNumber }}</div>
                        </div>

                        <div class="item-row">
                            <div class="item-name">Company</div>
                            <div class="item-value text-overflow">{{ load.broker.name }}</div>
                        </div>

                        <div class="item-row" v-if="load.brokerContactName">
                            <div class="item-name">Agent name</div>
                            <div class="item-value text-overflow">{{ load.brokerContactName }}</div>
                        </div>

                        <div class="item-row" v-if="load.brokerContactPhone">
                            <div class="item-name">Agent phone</div>
                            <div class="item-value text-overflow">{{ load.brokerContactPhone }}</div>
                        </div>

                        <div class="item-row" v-if="load.brokerContactEmail">
                            <div class="item-name">Agent email</div>
                            <div class="item-value text-overflow">{{ load.brokerContactEmail }}</div>
                        </div>

                    </div>
                </div>

                <div class="load-item">
                    <div class="name">
                        <div class="text">Documents</div>
                        <div class="link" v-if="loadDocumentsUploader">

                            <DropDownMenuButtonCustom title="Upload" showOnTop @upload="loadDocumentsUploader.uploadDoc"
                                :options="loadDocumentsUploader.uploadOptions || []">
                                <div style="display: flex; align-items: center; gap: 5px;">
                                    <a href="" @click.prevent>Upload</a>
                                    <Spinner size="xs" v-if="isLoading(['FileStorageUpload'])"/>
                                </div>
                            </DropDownMenuButtonCustom>


                        </div>
                    </div>

                    <div class="value filled">
                        <LoadDocumentsList @ready="loadDocumentsUploader = $refs['loadDocumentsUploader']"
                            ref="loadDocumentsUploader" :loadId="loadId" />
                    </div>

                </div>


                <div class="load-item">
                    <div class="name">
                        <div class="text">Dispatcher</div>
                        <div class="link" v-if="isAllowedToChangeDispatcher"><a href=""
                                @click.prevent="editLoadDispatcher">Edit</a></div>
                    </div>
                    <div class="value filled profile">
                        <div class="image" :style="[`background-image: url('${userImage}')`]"></div>
                        <div class="grid gap-5">
                            <div class="displayName"><a href="" @click.prevent>{{ userName }}</a></div>
                            <div class="user-role">{{ load.user.role ? load.user.role.name : 'Dispatcher' }}</div>
                        </div>
                    </div>
                </div>

                

                <div class="load-item" v-if="load.businessIncome && isBusinessDetailsShowAllowed">
                    <div class="name">
                        <div class="text">Business Income</div>
                    </div>
                    <div class="value filled grid gap-10">

                        <div class="item-row" v-if="load.userPay">
                            <div class="item-name">Dispatcher Earn</div>
                            <div class="item-value text-overflow">${{ CONVERT_PRICE(load.userPay.amount.toFixed(2)) }}
                                ({{ load.userPay.percent }}%)</div>
                        </div>

                        <div class="item-row" v-if="load.referralPay">
                            <div class="item-name">Referral Earn</div>
                            <div class="item-value text-overflow">${{ CONVERT_PRICE(load.referralPay.amount.toFixed(2))
                                }} ({{ load.referralPay.percent }}%)</div>
                        </div>

                        <div class="item-row" v-if="load.carrierCharge">
                            <div class="item-name">Carrier Charge</div>
                            <div class="item-value text-overflow">${{
                                CONVERT_PRICE(load.carrierCharge.amount.toFixed(2)) }} ({{ load.carrierCharge.percent
                                }}%)</div>
                        </div>

                        <div class="item-row">
                            <div class="item-name">Business Net Income</div>
                            <div class="item-value text-overflow">${{ CONVERT_PRICE(load.businessIncome.toFixed(2)) }}
                            </div>
                        </div>

                    </div>
                </div>

                

            </div>

        </div>

        <div v-else>
            <div v-if="isLoading(['GetLoadDetails'])" class="loader">
                <Spinner />
            </div>
            <div v-else>
                <NoDataMessage text="Can't find what you are looking for" />
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import LoadStops from './LoadStops.vue'
import EditLoadDetailsView from './EditLoadDetails.vue'
import EditCarrierDetailsView from './EditCarrierDetails.vue'
import EditBrokerDetailsView from './EditBrokerDetails.vue'
import EditLoadDispatcherView from './EditLoadDispatcher.vue'

import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup, LPolygon } from "@vue-leaflet/vue-leaflet";
import LoadDocumentsList from './LoadDocumentsList.vue';

export default {
    components: {
        LMap,
        LTileLayer,
        LoadDocumentsList
    },
    props: ['modal', 'loadId'],
    data() {
        return {
            load: null,
            loadDocumentsUploader: null
        }
    },
    computed: {
        ...mapGetters(['user']),
        userImage() {
            return this.load.user.image ? this.load.user.image : this.DEFAULTS.USER.IMAGE;
        },
        userName() {
            return this.load.user.displayName ? this.load.user.displayName : this.load.user.name + ' ' + this.load.user.lastname;
        },
        isAllowedToChangeDispatcher() {
            return this.user.isAuthorized('Loads', 'Edit dispatcher');
        },
        isBusinessDetailsShowAllowed() {
            return this.user.isAuthorized('Users', null);
        },
        isAllowedToEdit() {
            if (this.load.user._id === this.user._id) return true;
            return this.user.isAuthorized('Loads', 'Edit all');
        }
    },
    methods: {
        editLoadDispatcher() {
            let modal = this.$ShowModal({
                title: 'Select New Dispatcher',
                component: EditLoadDispatcherView,
                props: {
                    loadId: this.loadId
                }
            });
            modal.onClose(() => {
                this.getLoadDetails();
            });
        },
        editLoadStops() {
            let stops = this.load.stops || [];
            stops = JSON.parse(JSON.stringify(stops));

            let modal = this.$ShowModal({
                title: 'Edit Load Stops',
                component: LoadStops,
                props: {
                    stops: stops,
                    errors: {}
                }
            });

            modal.onConfirm(async (props) => {
                modal.processing(true);
                await this.ajax('UpdateLoad-ValidateLoadStops', { url: '/dispatch/loads/TestStops', method: 'POST', data: props.stops }, (err, body) => {
                    if (err) {
                        this.$ShowAlert(body.message || TEXT_ERRORS['SOMETHING_WRONG']);
                        return;
                    }
                    this.load.stops = body;
                    this.updateLoadStops(body);
                    modal.close();
                });
                modal.processing(false);
            });

        },
        updateLoadStops(stops) {
            this.ajax('UpdateLoad-LoadStops', { url: `/dispatch/loads/${this.loadId}/load-stops`, method: 'PUT', data: { stops: stops } }, (err, body) => {
                if (err) {
                    this.$ShowAlert(body.message || TEXT_ERRORS['SOMETHING_WRONG']);
                    return;
                }
                this.getLoadDetails();
            });
        },
        editLoadDetails() {
            let modal = this.$ShowModal({
                title: 'Edit Load Details',
                component: EditLoadDetailsView,
                props: {
                    loadId: this.loadId
                }
            });
            modal.onClose(() => {
                this.getLoadDetails();
            });
        },
        editCarrierDetails() {
            let modal = this.$ShowModal({
                title: 'Edit Carrier Details',
                component: EditCarrierDetailsView,
                props: {
                    loadId: this.loadId
                }
            });
            modal.onClose(() => {
                this.getLoadDetails();
            });
        },
        editBrokerDetails() {
            let modal = this.$ShowModal({
                title: 'Edit Broker Details',
                component: EditBrokerDetailsView,
                props: {
                    loadId: this.loadId
                }
            });
            modal.onClose(() => {
                this.getLoadDetails();
            });
        },
        async getLoadDetails() {
            this.modal.processing(true);

            this.ajax('GetLoadDetails', {
                url: `/dispatch/loads/${this.loadId}`,
                method: 'GET'
            }, (err, body) => {
                if (err) {
                    this.modal.close();
                    this.$ShowAlert(body.message || TEXT_ERRORS['SOMETHING_WRONG']);
                    return;
                }
                this.load = body;
            });

            this.modal.processing(false);
        },
        stopDate(dates) {
            let start = moment.utc(dates.start);
            let end = moment.utc(dates.end);
            if (start.isSame(end)) {
                return start.format("ddd D, MMM hh:mm A");
            }
            return start.format("ddd D, MMM hh:mm A") + ' - ' + end.format("ddd D, MMM hh:mm A");
        }
    },
    mounted() {
        if (!this.loadId) {
            this.modal.close();
            return;
        }
        this.getLoadDetails();
    }
}
</script>

<style lang="scss" scoped>
.view-load-wrapper {
    display: grid;
    gap: 20px;
}


.mapHolder {
    width: 100%;
    padding-top: 50%;
    position: relative;

    .map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.load-details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    align-items: start;
    padding: 20px;
}

.loader {
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.load-item {
    display: grid;
    gap: 15px;

    .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $text;

        .text {
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    .value {
        color: $textShade;

        &.filled {
            border-radius: 10px;
            padding: 20px;
            background: $themeColor1;
            box-shadow: $boxShadow;
            border: 1px solid $borderColor;
        }
    }
}

.item-row {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: minmax(150px, 1fr) auto;

    .item-name {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
    }

    .item-value {
        font-size: 12px;
        text-align: right;

        &.rate {
            font-size: 20px;
            font-weight: bold;
            color: $success;
        }

        &.ratePerMile {
            font-size: 16px;
            font-weight: 600;
            color: $themeColor2;
        }
    }
}

.stops-list {
    .stop-item {
        color: $textShade;
        padding: 10px 0 10px 28px;
        position: relative;
        display: grid;
        row-gap: 2px;

        &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            width: 7px;
            height: 8px;
            background: $themeColor2;
            border: 1px solid $themeColor2;
            box-sizing: border-box;
            transform: translate(0px, 5px);
        }

        &::after {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            bottom: 8px;
            width: 1px;
            background: linear-gradient(180deg, rgba(88, 100, 255, 0) 0%, #5864FF 50%, rgba(88, 100, 255, 0) 100%);
            transform: translate(3px, 18px);
        }

        &:last-child {
            &::after {
                content: none;
            }
        }

        &.pick {
            &::before {
                background: transparent;
            }
        }

        .stop-type {
            height: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        .stop-address {
            text-transform: uppercase;
            margin-bottom: 10px;

            .stop-name {
                font-weight: 500;
                margin-bottom: 5px;
                font-size: 14px;
            }
        }

        .stop-date {
            font-size: 12px;
        }
    }
}

.profile {
    display: grid;
    align-items: center;
    grid-template-columns: 54px minmax(0, auto);
    column-gap: 15px;

    .image {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 17px;
    }

    .displayName {
        font-size: 14px;
    }

    .user-role {
        text-transform: uppercase;
        font-size: 12px;
        color: $textShade;
    }
}
</style>